// Order Details
$print-account-order-info-item-margin: rem-calc(0 30 0 0) !default;
$print-account-order-details-box-title-margin: rem-calc(20 0 10) !default;
$print-account-product-table-column-header-quantity-text-align: center !default;
$print-account-product-table-cell-total-text-align: center !default;
$print-account-product-quantity-value-color: color(text) !default;
$print-account-order-details-main-content-margin: rem-calc(15 0 0) !default;
$print-account-order-details-main-content-border-top: 1px solid color(border) !default;
$print-account-order-details-summary-margin: 0 !default;
$print-account-logo-img-filter: null !default;

@mixin lora-components-print-account {
    // Order Details
    .l-header,
    .l-account__header-actions,
    .l-account__top,
    .l-footer,
    .c-sticky-bar,
    .c-button,
    .c-order-info__print,
    .c-tabs.m-tertiary .c-tabs__controls,
    .h-hide-for-large,
    .c-product-table__actions,
    .c-order-details-summary__title,
    .c-form__row,
    .c-product-table__quantity-label,
    .c-product-table__cell.m-write-review,
    .c-toaster {
        display: none;
    }

    #DW-SFToolkit { // stylelint-disable-line
        display: none;
    }

    .c-order-details-summary {
        margin: $print-account-order-details-summary-margin;
    }

    .c-order-info__item {
        align-items: baseline;
        margin: $print-account-order-info-item-margin;
    }

    .c-product-table__cell.m-image {
        display: block;
    }

    .c-product-table__cell.m-details {
        width: 100%;
    }

    .c-product-table__cell.m-total {
        text-align: $print-account-product-table-cell-total-text-align;
    }

    .c-product-table__column-header.m-quantity {
        text-align: $print-account-product-table-column-header-quantity-text-align;
    }

    .c-product-quantity .c-product-quantity__value {
        color: $print-account-product-quantity-value-color;
        padding: 0;
    }

    .c-order-details-box__title {
        font-weight: bold;
        margin: $print-account-order-details-box-title-margin;
        padding: 0;
        text-align: $global-left;
    }

    .l-account__order-details-content {
        display: flex;
        flex-flow: column;
    }

    .l-account__order-details-main-content.c-tabs__panel {
        border-top: $print-account-order-details-main-content-border-top;
        display: block;
        margin: $print-account-order-details-main-content-margin;
        order: 1;
    }

    .l-account__order-details-aside-content.c-tabs__panel {
        display: block;
        order: 2;
        width: 50%;
    }

    .c-logo img {
        filter: $print-account-logo-img-filter;
    }

    .c-cart-summary-table__item.m-total .c-cart-summary-table__cell {
        padding: 0;
    }

    // Unbreakable sections
    .c-order-details-box,
    .c-product-table__body {
        page-break-inside: avoid;
    }
    // End Order Details
}
